.panel {
    display: block;
    border-color: map-get($flex-list, types, default, divider);

    &.collapsed {
        .panel-header .caret:before { transform: rotate(90deg);}
    }

    .panel-content {
        display: flex;
        flex-flow: column nowrap;
        border-color: inherit;
    }

    .panel-header {
        flex: 1 0 60px;
        position: relative;
        outline: none;
        border-color: inherit;
        
        [panel-header] {
            border-color: inherit;
        }

        &.caret-left {  
        [panel-header] { padding-left: 60px;}  
            .caret { left: 0;}
        }

        &.caret-right {            
            [panel-header] { padding-right: 60px;}   
            .caret { right: 0;}
        }

        .caret {
           position: absolute;
           width: 60px;
           height: 100%;
           @include flexbox;
           align-items: center;
           justify-content: center;         

            &:before {
                transform: rotate(-90deg);
                transition: transform 250ms ease-out;
            }
        }

        &:focus {
            outline: none;
        }
    }

    &.expandable {
        .panel-header {
            cursor: pointer;
        }
    }

    .panel-body {
        height: 0;       
        transition: min-height 250ms ease;
        overflow-y: hidden;

        &:not(.open) {
            overflow-y: hidden;
        }
    }

    &.bordered {
        border-width: 1px;
        border-style: solid;
        border-radius: 8px;
        margin-bottom: 16px;

        .panel-body {
            overflow-y: hidden;
        }

        &.collapsed {
            background: theme-color(--primary, 0.05);
            border-color: theme-color(--primary);
        }
    }
}

.panel-list {
    .panel {
        padding: 0 3*$spas;
        transition: background-color 250ms linear;
        overflow-y: hidden;

        &:first-child {
            [panel-header] {
                border-top-style: solid;
                border-top-width: 1px;
            }
        }
        [panel-header] {
            border-bottom-style: solid;
            border-bottom-width: 1px;
        }

        .panel-header {
            @include flex-grow(1);
            @include flex-shrink(0);
            @include flex-basis(56px);
            .caret {
                width: 20px;
                color: theme-color(--shade-60);
                font-size: 14px;
            }
        }

        &:focus {
            outline: none;
        }
    }

    @include breakpoint_to(sm) {    
        .panel {
            padding: 0 2*$spas;
        }
    }
}
