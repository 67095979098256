.dashboard {
    @include flexbox;
    @include flex-flow(column nowrap);
    width: 100%;
    height: 100%;
    flex-grow: 1;

    .dashboard-header {
        @include flexbox;
        @include flex-flow(column nowrap);
        flex-shrink: 0;
        width: 100%;
        z-index: deep-map($z-index, toolbar);

        .toolbar {
            max-height: 64px;
        }
    }

    .dashboard-body {
        @include flexbox;
        @include flex-flow(column nowrap);
        @include flex-grow(1);
        @include flex-shrink(1);
        @include flex-basis(100%);
        justify-content: flex-start;
        flex-grow: 1;
        position: relative;
        width: 100%;
        overflow-y: auto;
        padding: deep-map($dashboard, padding);
    }

    .dashboard-empty-state {

        margin: auto;      
       

        @include breakpoint-from(md) {

            .empty-state {
            i, .icon {
                font-size: 80px;
            }

            .title {
                font-size: 1.6em;
                }
            }            
        }        
    }

    .dashboard-body-header {
        margin-bottom: $spas;

        @include breakpoint-from(sm) {
            margin-bottom: 2*$spas;
        }

        .dashboard-title {
            margin: 0;            
        }

        .dashboard-lead {
            font-size: 1em;
            margin-top: -4px;
        }
    }

    /// dashboard styles depending on breakpoints
    @each $breakpoint, $map in deep-map($dashboard, sizes) {
        @include breakpoint-from($breakpoint){

            .dashboard-body {
                padding: map-get($map, padding);
            }
       
        } 
    }
}