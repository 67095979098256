.pagination {
    display: flex;
    align-items: center;
    margin: 64px 0 64px 0;
    padding: 24px;
    position: relative;
    @include extendElevation(0);

    .label {
        font-size: 18px;
    }

    .ngx-pagination {
        margin: 0 0 0 30px;
        padding: 0;
        li {
            width: 40px;
            height: 36px;
            line-height: 35px;
            font-size: 16px;
            color: theme-color(--shade-80);
            border-radius: 4px;
            @include extendElevation(1);
            border: 1px solid theme-color(--shade-60);
            margin-right: 16px;
            text-align: center;
            padding: 0;
            overflow: visible;

            a {
                padding: 0;
                color: theme-color(--shade);
                &:hover {
                    background: none;
                }

                &:focus {
                    outline:none;
                }
            }

            &.current {
                background-color: theme-color(--primary);
                border-color: theme-color(--primary);
                color: theme-color(--light);
            }
        }

        .pagination-previous,
        .pagination-next {
            display: none;
            visibility: collapse;
        }
    }

}