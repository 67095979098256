// !You can NOT simply rename icons here, all new icons and all alterations must be made in icomoon
// after that ^ the font needs to be redownloaded here ../../fonts/centralIcons/ and icon naming updated in this file here
// icons are used in multiple places, so their naming should not be bound to a specific case
@font-face {
    font-family: 'connect-font';
    src:url('../../fonts/centralIcons/connect_font.woff?4z9f8y') format('woff'),
        url('../../fonts/centralIcons/connect_font.eot?4z9f8y#iefix') format('embedded-opentype'),
        url('../../fonts/centralIcons/connect_font.ttf?4z9f8y') format('truetype'),
        url('../../fonts/centralIcons/connect_font.svg?4z9f8y#connect-font') format('svg');
    font-weight: normal;
    font-style: normal;
}

[class^="connect_icon_"],
[class*=" connect_icon_"] {
    /* use !important to prevent issues with browser extensions that change fonts */
    font-family: 'connect-font' !important;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    vertical-align: middle;
    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.connect_icon_forwarding:before {
    content: "\e900";
}

.connect_icon_key:before {
    content: "\e903";
}

.connect_icon_offers:before {
    content: "\e800";
}

.connect_icon_premium:before {
    content: "\e901";
}

.connect_icon_dashboard:before {
    content: "\e923";
}

.connect_icon_referral:before {
    content: "\e97b";
}

.connect_icon_referral_alt:before {
    content: "\e97c";
}

.connect_icon_phone_card:before {
    content: "\e97a";
}

.connect_icon_more_horizontal:before {
    content: "\e978";
}

.connect_icon_reset_wifi_password:before {
    content: "\e918";
}

.connect_icon_vulnerabilities_found:before {
    content: "\e91e";
}

.connect_icon_boot_options:before {
    content: "\e931";
}

.connect_icon_fix_apply:before {
    content: "\e971";
}

.connect_icon_optimization_start:before {
    content: "\e972";
}

.connect_icon_arrow-down-triangle:before {
    content: "\e973";
}

.connect_icon_check_outline:before {
    content: "\e974";
}

.connect_icon_lock_open:before {
    content: "\e975";
}

.connect_icon_private_data:before {
    content: "\e976";
}

.connect_icon_computer_setting:before {
    content: "\e977";
}

.connect_icon_launch:before {
    content: "\e909";
}

.connect_icon_phone_restricted:before {
    content: "\e90a";
}

.connect_icon_phone_shielded:before {
    content: "\e96a";
}

.connect_icon_disk_space:before {
    content: "\e96b";
}

.connect_icon_empty_page:before {
    content: "\e96c";
}

.connect_icon_user_setting:before {
    content: "\e96d";
}

.connect_icon_issues_flag:before {
    content: "\e96e";
}

.connect_icon_registry_keys:before {
    content: "\e96f";
}

.connect_icon_windows_update:before {
    content: "\e970";
}

.connect_icon_tips:before {
    content: "\e95f";
}

.connect_icon_no_scan:before {
    content: "\e960";
}

.connect_icon_quick_scan:before {
    content: "\e961";
}

.connect_icon_system_scan:before {
    content: "\e962";
}

.connect_icon_disconnected:before {
    content: "\e91a";
}

.connect_icon_connected:before {
    content: "\e919";
}

.connect_icon_info:before {
    content: "\e91b";
}

.connect_icon_safety_tips:before {
    content: "\e62b";
}

.connect_icon_unresolved:before {
    content: "\e916";
}

.connect_icon_unprotected:before {
    content: "\e915";
}

.connect_icon_protected:before {
    content: "\e917";
}

.connect_icon_error_triangle:before {
    content: "\e920";
}

.connect_icon_iot:before {
    content: "\e95c";
}

.connect_icon_linux:before {
    content: "\e963";
}

.connect_icon_ios:before {
    content: "\e95b";
}

.connect_icon_android:before {
    content: "\e616";
}

.connect_icon_apple:before {
    content: "\e617";
}

.connect_icon_osx:before {
    content: "\e617";
}

.connect_icon_mac:before {
    content: "\e617";
}

.connect_icon_windows:before {
    content: "\e618";
}

.connect_icon_ubuntu:before {
    content: "\e930";
}

.connect_icon_idtheft:before {
    content: "\e97d";
}

.connect_icon_dip:before {
    content: "\e946";
}

.connect_icon_password_manager:before {
    content: "\e97e";
}

.connect_icon_vpn:before {
    content: "\e97f";
}

.connect_icon_vpn_squircle:before {
    content: "\e964";
}

.connect_icon_vpn_squircle_outline:before {
    content: "\e965";
}

.connect_icon_protection_squircle:before {
    content: "\e966";
}

.connect_icon_protection_squircle_outline:before {
    content: "\e967";
}

.connect_icon_parental_squircle:before {
    content: "\e968";
}

.connect_icon_parental_squircle_outline:before {
    content: "\e969";
}

.connect_icon_generic_device:before {
    content: "\e950";
}

.connect_icon_user:before {
    content: "\e952";
}

.connect_icon_remove:before {
    content: "\e953";
}

.connect_icon_edit:before {
    content: "\e954";
}

.connect_icon_scan_page:before {
    content: "\e955";
}

.connect_icon_update:before {
    content: "\e957";
}

.connect_icon_community:before {
    content: "\e94b";
}

.connect_icon_clipboard:before {
    content: "\e949";
}

.connect_icon_exclamation_circle:before {
    content: "\e93f";
}

.connect_icon_question:before {
    content: "\e948";
}

.connect_icon_checkmark_circle:before {
    content: "\e94a";
}

.connect_icon_plus_circle:before {
    content: "\e979";
}

.connect_icon_external_link:before {
    content: "\e940";
}

.connect_icon_info_letter:before {
    content: "\e941";
}

.connect_icon_phone_call:before {
    content: "\e936";
}

.connect_icon_mail_open:before {
    content: "\e938";
}

.connect_icon_sphere:before {
    content: "\e93a";
}

.connect_icon_unknown:before {
    content: "\e935";
}

.connect_icon_session:before {
    content: "\e934";
}

.connect_icon_play:before {
    content: "\e933";
}

.connect_icon_camera:before {
    content: "\e932";
}

.connect_icon_time_limit:before {
    content: "\e92b";
}

.connect_icon_phone:before {
    content: "\e928";
}

.connect_icon_mail:before {
    content: "\e613";
}

.connect_icon_picture:before {
    content: "\e925";
}

.connect_icon_exclamation_mark:before {
    content: "\e926";
}

.connect_icon_threat:before {
    content: "\e91d";
}

.connect_icon_file:before {
    content: "\e601";
}

.connect_icon_help:before {
    content: "\e602";
}

.connect_icon_subscription:before {
    content: "\e605";
}

.connect_icon_notification:before {
    content: "\e606";
}

.connect_icon_parental:before {
    content: "\e607";
}

.connect_icon_success:before {
    content: "\e608";
}

.connect_icon_denied:before {
    content: "\e609";
}

.connect_icon_more:before {
    content: "\e60d";
}

.connect_icon_check:before {
    content: "\e626";
}

.connect_icon_close:before {
    content: "\e628";
}

.connect_icon_check_small:before {
    content: "\e614";
}

.connect_icon_close_small:before {
    content: "\e61d";
}

.connect_icon_plus:before {
    content: "\e615";
}

.connect_icon_download:before {
    content: "\e610";
}

.connect_icon_locate:before {
    content: "\e612";
}

.connect_icon_trash:before {
    content: "\e61c";
}

.connect_icon_desktop_large:before {
    content: "\e61e";
}

.connect_icon_mobile_large:before {
    content: "\e620";
}

.connect_icon_settings:before {
    content: "\e627";
}

.connect_icon_mobile:before {
    content: "\e629";
}

.connect_icon_account:before {
    content: "\e62c";
}

.connect_icon_area_large:before {
    content: "\e62d";
}

.connect_icon_logout:before {
    content: "\e62e";
}

.connect_icon_closed_large:before {
    content: "\e62f";
}

.connect_icon_closed:before {
    content: "\e630";
}

.connect_icon_search:before {
    content: "\e639";
}

.connect_icon_denied_box:before {
    content: "\e63b";
}

.connect_icon_eye:before {
    content: "\e63c";
}

.connect_icon_password:before {
    content: "\e63d";
}

.connect_icon_success_box:before {
    content: "\e640";
}

.connect_icon_interests:before {
    content: "\e645";
}

.connect_icon_calendar:before {
    content: "\e646";
}

.connect_icon_arrow_back:before {
    content: "\e801";
}

.connect_icon_arrow_next:before {
    content: "\e93b";
}

.connect_icon_error:before {
    content: "\e902";
}

.connect_icon_ceas:before {
    content: "\e907";
}

.connect_icon_right:before {
    content: "\e90d";
}

.connect_icon_left:before {
    content: "\e91f";
}

.connect_icon_up:before {
    content: "\e921";
}

.connect_icon_down:before {
    content: "\e922";
}