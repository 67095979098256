.expandable-list {
    @include flexbox;
    @include flex-wrap(wrap);
    margin-left: -$gridGutter;
    margin-right: -$gridGutter;
    position: relative;
    
    &.expanded .expandable-element:not(.expanded) {
        opacity: .5;
    }

    .expandable-element {
        height: 128px;
        padding: 0 12px;
        position: static;
        margin-top: -1px;
        transition: margin-bottom 0.4s ease-out, opacity 0.2s;

        .trigger {
            height: 128px;
            display: flex;
            position: relative;
            align-items: center;
            border-bottom:1px solid theme-color(--shade-20);
            border-top: 1px solid theme-color(--shade-20);
            font-size: 18px;
            padding-right: 40px;
            cursor: pointer;

            .caret {
                position: absolute;
                height: 100%;
                right: 0;
                display: flex;
                align-items: center;
                color: theme-color(--shade-60);
                font-size: 16px;

                &:before {
                    transform: rotate(-90deg);
                    transition: transform 250ms ease-out;
                }
            }
        }

        .content {
            box-sizing: border-box;
            position: absolute;
            left: 12px;
            right: 12px;
            background-color: theme-color(--shade-5);
            align-items: center;
            padding: 0 40px;
            overflow: hidden;
            margin-top: -1px;
            transition: height 0.4s ease-out;
            display:flex;
            align-items: center;

            div {
                width: 100%;
                overflow-wrap: break-word;
            }

            @include breakpoint-to(sm) {
                padding: 0 20px;
            }
        }

        &.expanded {
            .trigger {
                border-top:1px solid theme-color(--primary);
                font-weight: bold;

                .caret:before {
                    transform: rotate(90deg);
                }
            }
        }
    }
}

