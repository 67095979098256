/// Tabs component
/// Tabs rely heavily on inheritance
/// it has transparent bg so that it can be easily adapted in any condition usualy it is used inside a toolbar which sets the tone

/// Tabs Layout

.tabs {
    display: flex;  
    height: 100%;
    max-width: 100%;

    position: relative;  
    overflow-x: auto;
    overflow-y: hidden;
    -webkit-overflow-scrolling: touch;
    
    &::-webkit-scrollbar { display: none; }       

    nav {
   
       height: 100%;
       transition: transform 150ms ease-out;

       @include flexbox;
       @include align-items(stretch);
       @include flex-flow(row nowrap);

       .tab {
           height: 100%;
           @include flexbox;
           flex-flow: row nowrap;
           align-items: center;
           cursor: pointer;
           white-space: nowrap;
           text-decoration: none;
       }
    }

    .scroll-tab {
        position: absolute;

        &.left {
            left:0;
        }

        &.right { right: 0;}
    }

    .tab {
        padding: 0 2*map-get($tabs, padding);
        transition: all 150ms ease-out;

        &:before {
            content: "";
            position: absolute;
            left: 0;
            bottom: 0;
            height: 2px;
            width: 100%;
            color: inherit;
            background: currentColor;
            transform: translateY(100%);
        }
    
        &.active {
            position: relative;
    
            &:before {
                transform: translateY(0);
                transition: transform 250ms 50ms;
            }
        }
    }
}

/// Tabs Design
/// default looks is set in _vars file

.tabs .tab {
    color:  map-get($tabs, color);
        
    &:hover,
    &.active {
        color: map-get($tabs, highlightColor);
        background-color: map-get($tabs, highlightBg);
    }

    &:active {
        background-color: map-get($tabs, highlightBg);
    }
}
