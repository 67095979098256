//// Bitdefender breakpoints mixin
$breakpoints: deep-map($grid, breakpoints);
$height-breakpoints: deep-map($grid, height-breakpoints);

@mixin breakpoint-to($max){
    @if map-has-key($breakpoints, $max) {
        @media (max-width: #{map-get($breakpoints, $max)}) {
          @content;
        }
      }

    @else {
        @warn "Unfortunately, no value could be retrieved from `#{$max}`. "
        + "Please make sure it is defined in `$breakpoints` map.";
    }
}

@mixin breakpoint-from($min){
    @if map-has-key($breakpoints, $min) {
        $minVal: map-get($breakpoints, $min) + 1;
        @media (min-width: $minVal) {
          @content;
        }
      }

    @else {
        @warn "Unfortunately, no value could be retrieved from `#{$min}`. "
        + "Please make sure it is defined in `$breakpoints` map.";
    }
}

@mixin landscape-to($max){
    @if map-has-key($height-breakpoints, $max) {
        @media (max-height: #{map-get($height-breakpoints, $max)}) {
          @content;
        }
      }

    @else {
        @warn "Unfortunately, no value could be retrieved from `#{$max}`. "
        + "Please make sure it is defined in `$height-breakpoints` map.";
    }
}

@mixin landscape-from($max){
    @if map-has-key($height-breakpoints, $max) {
        @media (min-height: #{map-get($height-breakpoints, $max)}) {
          @content;
        }
      }

    @else {
        @warn "Unfortunately, no value could be retrieved from `#{$max}`. "
        + "Please make sure it is defined in `$height-breakpoints` map.";
    }
}

@mixin breakpoint-between($min, $max){
    @if map-has-key($breakpoints, $min) and map-has-key($breakpoints, $max) {
        @media (min-width: map-get($breakpoints, $min) + 1) and (max-width: #{map-get($breakpoints, $max)}) {
          @content;
        }
      }

    @else {
        @warn "Unfortunately, no value could be retrieved from `#{$min}` and `#{$max}`. "
        + "Please make sure they are is defined in `$breakpoints` map.";
    }
}

/// Pls use breakpoint_from(sm) and breakpoint_to(sm)

// @mixin mobile-down($max-width: $mobile-portrait, $max-height:$mobile-landscape){
//     @media screen and (max-width : $max-width) and (orientation: portrait), screen and (max-width: $max-height) and (orientation: landscape) {
//         @content;
//     }
// }

// @mixin mobile-up($min-width: $mobile-portrait + 1, $min-height: $mobile-landscape + 1){
//     @media screen and (min-width : $min-width) and (orientation: portrait), screen and (min-width: $min-height) and (orientation: landscape) {
//         @content;
//     }
// }
