// popover styling
.popover {

    .popover-trigger:after {
        color: map-get($popover, background);
    }

    [popover-content] {
        background-color: map-get($popover, background);
        border-radius: map-get($popover, border-radius);
        box-shadow: map-get($popover, shadow);
        color: map-get($popover, color);
        font-size: map-get($popover, font-size);
        line-height: map-get($popover, line-height);   
        padding: map-get($popover, padding);
        letter-spacing: normal;
        font-weight: normal;
    }
}



// popover layout
$caretSize: 8px;
.popover {
    position: relative;
    overflow: visible;
    
    display: inline-flex;
    @include flex-wrap(nowrap);
    @include flex-direction(column);
    
    &.left, &.right {
        @include flex-direction(row);
    }

    &.start {
        @include align-items(flex-start);
    }

    &.end {
        @include align-items(flex-end);
    }

    &.center {
        @include align-items(center);
    }
  
    &.open {
        z-index: 100;
        .popover-trigger:after {
            display: block;
        }
    }

    .popover-wrapper {        
        position: absolute;  
    }

    &.top {
        .popover-wrapper { bottom: 100%; margin-bottom: $caretSize;}
        .popover-trigger:after { bottom: 100%; margin-bottom: -$caretSize;}
    }

    &.right {
        .popover-wrapper { left: 100%; margin-left: $caretSize;}
        .popover-trigger:after { left: 100%; margin-left: -$caretSize;}
    }
    &.bottom {
        .popover-wrapper { top: 100%; margin-top: $caretSize; }
        .popover-trigger:after { top: 100%; margin-top: -$caretSize;}
    }
    &.left {
        .popover-wrapper { right: 100%; margin-right: $caretSize; }
        .popover-trigger:after { right: 100%; margin-right: -$caretSize;}
    }

    &.top, &.bottom {
        .popover-trigger:after { left: 50%; transform: translateX(-50%);}
    }
    &.left, &.right {
        .popover-trigger:after { top: 50%; transform: translateY(-50%);}
    }


    // the caret
    .popover-trigger:after {
        content: "";
        display: none;
        position: absolute;
        width: $caretSize;
        height: $caretSize;
        border-width: $caretSize;
        box-sizing: border-box;
        border-style: solid;
        z-index: 1;
    }

    &.top .popover-trigger:after {
        border-color: currentColor transparent transparent transparent;
    }
    &.right .popover-trigger:after {
        border-color: transparent currentColor transparent transparent;
    }
    &.bottom .popover-trigger:after{ 
        border-color: transparent transparent currentColor transparent;
    }
    &.left .popover-trigger:after{
        border-color: transparent transparent transparent currentColor;
    }
}