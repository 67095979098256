ol.breadcrumb {
    list-style-type:none;
    display: flex;
    margin: 0 0 32px 0;
    li {
        padding: 0;
        &:before {
            font-family: 'connect-font';
            font-size: 10px;
            vertical-align:bottom;
            transform: rotate(180deg);
            content: "\e90d";
            display: inline-block;
            margin: -1px 4px;
            color: #005eff;
        }
        a {
            cursor: pointer;
            color: #005eff;
        }
    }
}