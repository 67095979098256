// UI Stepper, do not alter unless you test every stepper there is

.steps {

    @include flexbox;
    @include flex-flow(column nowrap);
    @include align-items(flex-start);
    @include justify-content(stretch);
    @include flex(1 1 100%);
    width: 100%;
    height: 100%;  
    max-height: 100vh; 
    overflow: hidden;
    position: relative;    

    progress {
        flex: 0 0 map-get($progress, height);
        transition: opacity 200ms ease-out;
        &.hidden {
            opacity: 0;
        }
    }    

    // do no style .step-illustration, this could be positioned beside content, or on top or anywhere
    // it should be styled in the designated steper where it is used

    .steps-nav {
        width: 100%;
        @include flexbox;
        @include flex-flow(row nowrap);
        @include flex-shrink(0);
        @include flex-basis(map-get($button, height));

        &.inactive {
            display: none;
        }

        .skip-btn {
            margin-left: auto;
        }

        > .btn { background: transparent; }
    }   

    .modal-footer {
        text-align: right;
    }

    .modal-content {
        min-height: 100%;

        // temp
        position: relative;
    }

    .steps-viewport {
        @include flexbox;
        @include flex-flow(row nowrap);
        @include flex(1 1 100%);
        width: 100%;
        height: 100%;
        position: relative;
        overflow-x: hidden;
        overflow-y: auto;
    }
    
    .step {
        width: 100%;
        min-height: 100%;
        @include flexbox;
        @include flex-flow(column nowrap);
        @include justify-content(stretch);
        @include flex-basis(100%);
        @include flex-grow(1);
        @include flex-shrink(0);
        
        overflow: hidden;

        &[hidden] {
            visibility: hidden;
        }

        .step-actions {
            padding-top: 16px;

            button { 
                padding: 0; 

                &.cancel {
                    min-width: deep-map($button, height);
                    margin-left: - calc($spas/2);
                }
            }

            & + .modal-body {
                margin-top: 0;
                margin-bottom: auto;
            }
        }

        .step-content {
            height: 100%;
        }
    }
}