// Bitdefender badge component
$badge-padding: calc($spas / 2);

.badge {
    display: inline-block;
    font-size: map-get($badge, font-size);
    line-height: map-get($badge, height) - 2*$badge-padding;
    padding: $badge-padding $badge-padding*2;
    box-sizing: content-box !important;
    border-radius: map-get($badge, border-radius);
    text-align: center;
    letter-spacing: map-get($badge, letter-spacing);
    vertical-align: middle;
   
    &.circle {
        border-radius: map-get($badge, font-size);        
        min-width: map-get($badge, height);
        padding-left: 0;
        padding-right: 0;
    }

    &.pill {
        border-radius: map-get($badge, font-size);        
        min-width: map-get($badge, height);
        padding: 5px 12px;
    }

    &.empty {
        border-radius: map-get($badge, font-size);        
        // min-width: map-get($badge, height);
        padding: 6px 6px;
    }

    &.top, &.right, &.bottom, &.left { position: absolute; }

    &.top { top:0; }
    &.right { right: 0; }
    &.bottom { bottom:0; }
    &.left { left: 0; }

    &.top.center, &.bottom.center {
        left: 50%;
        transform: translateX(-50%);
    }

    &.right.center, &.left.center {
        top: 50%;
        transform: translateY(-50%);
    }

    @include states(map-get($badge, types));
}