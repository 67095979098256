@font-face {
    font-family: 'centralDevices';
    src:url('../../fonts/centralDevices/centralDevices.woff') format('woff'),
        url('../../fonts/centralDevices/centralDevices.eot?#iefix') format('embedded-opentype'),
        url('../../fonts/centralDevices/centralDevices.ttf') format('truetype'),
        url('../../fonts/centralDevices/centralDevices.svg#centralDevices') format('svg');
    font-weight: normal;
    font-style: normal;
}
.device-icon {
    /* use !important to prevent issues with browser extensions that change fonts */
    font-family: 'centralDevices' !important;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
  
    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.box1:before {
    content: "\e93a";
}
.desktop:before {
    content: "\e93b";
}
.iot_hub:before {
    content: "\e93c";
}
.smart_display:before {
    content: "\e93d";
}
.wheather_sensor:before {
    content: "\e93e";
}
.set_top_box:before {
    content: "\e937";
}
.boxv2:before {
    content: "\e938";
}
.gps:before {
    content: "\e939";
}
.washing_machine:before {
    content: "\e902";
}
.vacuum:before {
    content: "\e903";
}
.smart_sprinkler:before {
    content: "\e905";
}
.smart_meter:before {
    content: "\e90e";
}
.sewing_machine:before {
    content: "\e911";
}
.smart_scale:before {
    content: "\e912";
}
.radar_detector:before {
    content: "\e928";
}
.doorphone:before {
    content: "\e929";
}
.other:before {
    content: "\e930";
}
.air_conditioning:before {
    content: "\e900";
}
.air_purifier:before {
    content: "\e901";
}
.baby_monitor:before {
    content: "\e904";
}
.car:before {
    content: "\e906";
}
.card_reader:before {
    content: "\e907";
}
.copyboard:before {
    content: "\e908";
}
.ereader:before {
    content: "\e909";
}
.extender:before {
    content: "\e90a";
}
.fridge:before {
    content: "\e90b";
}
.game_console:before {
    content: "\e90c";
}
.garage_door:before {
    content: "\e90d";
}
.health_device:before {
    content: "\e90f";
}
.home_monitoring:before {
    content: "\e910";
}
.tracking:before {
    content: "\e913";
}
.laptop:before {
    content: "\e914";
}
.mp3_player:before {
    content: "\e915";
}
.nas:before {
    content: "\e916";
}
.oven:before {
    content: "\e917";
}
.computer:before {
    content: "\e918";
}
.animal_tracker:before {
    content: "\e919";
}
.phone:before {
    content: "\e91a";
}
.pos:before {
    content: "\e91b";
}
.printer:before {
    content: "\e91c";
}
.prototyping_platform:before {
    content: "\e91d";
}
.router:before {
    content: "\e91e";
}
.sd_card:before {
    content: "\e91f";
}
.bed:before {
    content: "\e920";
}
.smart_doorbell:before {
    content: "\e921";
}
.smart_bulbs:before {
    content: "\e922";
}
.smart_doorlock:before {
    content: "\e923";
}
.generic_sensor:before {
    content: "\e924";
}
.smart_plug:before {
    content: "\e925";
}
.thermostat:before {
    content: "\e926";
}
.tv:before {
    content: "\e927";
}
.watch:before {
    content: "\e92a";
}
.solar_panel:before {
    content: "\e92b";
}
.speakers:before {
    content: "\e92c";
}
.streaming_device:before {
    content: "\e92d";
}
.switch:before {
    content: "\e92e";
}
.tablet:before {
    content: "\e92f";
}
.ups:before {
    content: "\e931";
}
.video_conferencing:before {
    content: "\e932";
}
.projector:before {
    content: "\e933";
}
.voip:before {
    content: "\e934";
}
.camera:before {
    content: "\e935";
}
.wireless_adapter:before {
    content: "\e936";
}
  