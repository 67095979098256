
/// Mixin to generate font-faces
/// @param {String} $fontName
/// @param {CssProp} $style normal / italic
/// @param {Number} $weight 400, 700 etc
/// @param {String} $weightName usualy the weight (Bold, Regular, Medium, etc)
/// @param {String} $variant latin, latin-ext, greek etc
/// @param $unicodeRange unicodes covered by the font-face
// ! the font path must match the pattern bellow
/// ...../fonts/fontName/fontName.toLower - variant/fontName.toLower - variant - weight

@mixin font-face($fontName, $style: normal, $weight, $weightName: 'Regular', $variant, $unicodeRange) {
    $name: to-lower-case($fontName);
    @font-face {
        font-family: $fontName;
        font-style: $style;
        font-weight: $weight;
        font-display: swap;
        src:    local('#{$fontName} #{$weightName}'), 
                local('#{$fontName}-#{$weightName}'),
                url('./../../core/fonts/#{$fontName}/#{$name}-#{$variant}/#{$name}-#{$variant}-#{$weight}.woff2') format('woff2'),
                url('./../../core/fonts/#{$fontName}/#{$name}-#{$variant}/#{$name}-#{$variant}-#{$weight}.woff') format('woff');
        unicode-range: $unicodeRange;
    }
}