@charset 'UTF-8';
@import '../../../core/mixins/font_face';

/// unicode-range: Unicode suported by the font., in this case Roboto
/// If the loaded page does not use any of the characteres covered by the font-face, the font-face will not be unnecessarily loaded
$cyrillic-unicode: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
$cyrillic-ext: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
$greek: U+0370-03FF;
$greek-ext: U+1F00-1FFF;
$latin-unicode: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
$latin-ext: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
$vietnamese: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB;

@include font-face('Roboto', normal, 700, 'Bold', 'latin-ext', $latin-unicode + $latin-ext);
@include font-face('Roboto', normal, 500, 'Medium', 'latin-ext', $latin-unicode + $latin-ext);
@include font-face('Roboto', normal, 400, 'Regular', 'latin-ext', $latin-unicode + $latin-ext);
@include font-face('Roboto', normal, 300, 'Light', 'latin-ext', $latin-unicode + $latin-ext);