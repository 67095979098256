.toasts {
    font-size: 1em;
    position: absolute;   
    margin: map-get($toast, margin);
    @include flexbox;
    flex-flow: column;
    z-index: map-get($z-index, toast);

    /// default position bottom right
    right: 0;
    bottom: 0;
    flex-direction: column-reverse;

    &[position*="top"] {
        top: 0;
        bottom: auto;
        flex-direction: column;
    }

    &[position*="left"] {
        left: 0;
        right: auto;
    }


    .toast {
        + .toast { margin-bottom: map-get($toast, margin); }
    }

}


.toast {   
    border-radius: map-get($toast, border-radius);
    background: map-get($toast, default, bg);
    color: map-get($toast, default, color);
    padding: 0 map-get($toast, padding); 
    @include flexbox;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: space-between;
    transition: all 0.15s ease-in;

    @include states(map-get($toast, types));

    .toast-inner {
        padding: map-get($toast, padding);      

        p { margin: 0; color: inherit;}        
    }

    .toast-close { 
        padding: map-get($toast, padding); 
        cursor: pointer;
    }

}