%bgGradient {
    background: linear-gradient(to right, map-get($placeholder, lightColor) 8%, map-get($placeholder, color) 18%, map-get($placeholder, lightColor) 33%);
    -webkit-background-size: 800px 104px;
    background-size: 1200px 104px;
    @include animate(gradient, 1.25s, null, infinite);
}

.progressive-placeholder {
    max-width: 100%;
    height: 100%;
    box-sizing: border-box;
    background: map-get($placeholder, background);
    border-radius: map-get($card, border-radius);
    @include animate(gradient, 1.25s, null, infinite);

    .progressive-header {
        @include flexbox;
        .title { width: 100%; }
        .main-title { width: 40%; }
        .sub-title { width: 65%; }
    }

    .progressive-content {
        max-width: 100%;
        .description1 { width: 100%; }
        .description2 { width: 90%; }
    }

    .description1, .description2, .main-title, .sub-title, .thumb{
        min-height: 10px;
        line-height: 0;
        @extend %bgGradient;
    }

    .thumb {
        font-size: 12 * map-get($placeholder, size);
        width: 1em;
        height: 1em;
        flex-basis: 1em;
        flex-shrink: 0; 
        border-radius: 1em;
    }

    button {
        min-width: 30 * map-get($placeholder, size);
        height: map-get($button, size, sm, height);
        border-radius: map-get($button, border-radius);
        cursor: default;
        @extend %bgGradient;
    }

    .actions {
        font-size: map-get($placeholder,size);
        width: 1em;
        height: 1em;
        border-radius: 1em;
        @extend %bgGradient;

        &::before, &::after {
            content: " ";
            display: block;
            background: inherit;
            font-size: map-get($placeholder, size);
            width: 1em;
            height: 1em;
            border-radius: 1em;
        }

        &::before {
            transform: translate(0px, -2em);
        }

        &::after {
            transform: translate(0px, 1em);
        }
    }
}