.empty-state {
    width: 100%;
    color: theme-color(--shade);
    color: inherit;

    .empty-state-content {
        padding: 2*$spas;
        margin: auto;
        text-align: center;
    }

    .title, p {
        margin-top: 2*$spas;
        margin-bottom: 2*$spas;
    }

    .title, .details, p, i {
        color: inherit;
        white-space:pre-wrap;
    }

    .btn {
        min-width: 120px;
    }

    .empty-state-image { max-width: 100%; }

    &.page {

        @include flexbox;
        @include flex-flow(row nowrap);
        @include align-items(flex-start);
        @include justify-content(flex-start);
        height: 100%;

        .empty-state-icon, .icon {
            font-size: 3em;
        }
    }

    &.card {
        .empty-state-icon {
            font-size: 2em;
        }
    }
}