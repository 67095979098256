/// Drawer component - created 23.10.2018
/// see _vars file for customization

/// Drawer cand be positioned @ left or right, left is default and doesn't need to be specified / for right position="right" or [position]="variableControllingPosition"
/// Drawer cand push(without shrinking of main), side (with shrinking of main) or hover main. type="push", type="side", type="over" 
/// (push - pushes drawer-main as it is, out of the viewport)
/// (side - pushed the drawer-main shrinking it to fit the viewport)
/// (over - does nothing to main / with "over" the drawer is positioned absolute)


/// Extra: Drawer can have unfold looking style (add class .unfold to class .drawer for that), it goes with push-mode, or side-mode drawer

.drawer-viewport {
    display: flex;
    flex-flow: row nowrap;
    overflow: hidden;
    height: 100%;
    position: relative;
    z-index: map-get($z-index, main);


    .drawer {
        @include flexbox;
        @include flex-flow(column nowrap);
        @include align-items(flex-end);
        overflow: hidden;
        max-width: 100%;

        &:focus {
            outline: none;
        }

        .drawer-content {
            min-width: 100%;
            overflow-y: auto;
            height: 100%;
        }


        &.left {
            @include align-items(flex-end);

            &.unfold {
                @include align-items(flex-start);
            }
        }

        /// default position is left, but drawer cand also be positioned at right
        &.right {
            @include order(1);
            @include align-items(flex-start);

            &.unfold {
                @include align-items(flex-end);
            }
        }

        /// Some styling: drawer color, background and text color
        @each $class, $drawer in map-get($drawer, types) {
           &.#{$class} {
                background: map-get($drawer, background);
                color: map-get($drawer, color);
           }
        }
    }

    .drawer-backdrop {
        position: absolute;
        width: 100%;
        height: 100%;
        background: map-get($drawer, backdrop);
        top: 0;
        left: 0;
        border: 0 none;
        cursor: pointer;
        z-index: map-get($z-index, main);
    }
      
    .drawer-main {
        @include flexbox;
        @include flex-flow(column nowrap);
        @include flex-grow(1);
        @include flex-shrink(1);
        @include flex-basis(100%);
        @include justify-content(flex-start);
        position: relative;
        overflow: auto;
        z-index: map-get($z-index, main);
    }   
    
    /// drawer types

    /// 1. OVER drawer,  it's default drawer type.
    .drawer.over {
        @include flex-grow(1);
        @include flex-shrink(0);
        position: absolute;
        top:0;
        bottom: 0;
        transition: transform map-get($drawer, transition, speed) map-get($drawer, transition, easeing);

        .drawer-content {
            max-width: 100%;
        }

        &.left {
            left: 0;
            transform: translateX(-100%);
        }
        
        &.right{
            right: 0;  
            transform: translateX(100%);   
        }

        &.open {
            transform: translateX(0);     
            z-index: map-get($z-index, drawer);    
        }
    }   
        
    /// 2. SIDE drawer, It pushes the main to shrink in the remaining available space
    .drawer.side {
        @include flex-grow(1);
        @include flex-shrink(0);
        @include flex-basis(0%);
        transition: flex map-get($drawer, transition, speed) map-get($drawer, transition, easeing);

        &.open {
            flex-basis: map-get($drawer, width);
            @include flex-shrink(0);
            z-index: map-get($z-index, drawer);
        }      
    }

    /// 3. PUSH drawer. It pushes the main but the main does not shrink to fit available space    
    /// One ui-drawer can have just one pushing drawer at a time

    .drawer.push{
        @include flex-grow(1);
        @include flex-shrink(0);
        @include flex-basis(0%);
        transition: flex map-get($drawer, transition, speed) map-get($drawer, transition, easeing);

        &.open {
            flex-basis: map-get($drawer, width);
            @include flex-shrink(0);
            z-index: map-get($z-index, drawer);
        }       

        & + .drawer-main {
            @include flex-shrink(0);
        }
    }

    /// Displaced behaviour, moves the main without shrinking it
    /// Displaced.left requires a "push" type drawer 
    /// Displaced.right requires a "push" type drawer positioned at right [position="right"]
      
    &.displace {       
        > .drawer { z-index: map-get($z-index, drawer);}
        > .drawer-main {
            width: 100%;
            flex-shrink: 0;
        }
    }

    &.displace.left {
        justify-content: flex-start;        
    }

    &.displace.right {
        justify-content: flex-end;
    }

}