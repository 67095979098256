.chips {
   
    .chip {
        @include flexbox;
        padding: $spas 2*$spas;
        background: map-get($chip, background);
        border: 1px solid map-get($chip, border-color);
        border-radius: map-get($chip, border-radius);

        &.selected {
            background: theme-color(--primary, 0.05);
            border-color: theme-color(--primary);

            &.danger {
                background: transparent;
                border-color: theme-color(--danger);
            }

            &.success {
                background: theme-color(--success, 0.05);
                border-color: theme-color(--success);
            }
        }
    }
}