.sliding-panes {
    // @extend .flex;
    // @extend .column;
    // @extend .nowrap;
    position: relative;
    height: 100%;
    overflow: hidden;

    .sliding-pane {
        width: 100%;
        height: 100%;
        position: relative;
        transition: transform 350ms ease-out;
        transform: translateX(0);

        &.slide-left {
            transform: translateX(-100%);
            position: absolute;
            top: 0;
        }

        &.slide-right {
            transform: translateX(100%);
            position: absolute;
            top: 0;
        }
    }
}