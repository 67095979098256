/// styling the Ui-Dropdown component

.dropdown {
    position: relative;
    @include flexbox();
    @include flex-flow(column nowrap);
    font-size: 1em;

    &.open {
        .dropdown-menu {
            opacity: 1;
            z-index: deep-map($z-index, fixed-screen-blockers);
            pointer-events: initial;
            transition: all 250ms ease-out;

            &.down { margin-top: $spas;}
            &.top { margin-bottom: $spas;}
        }

        [dropdown-trigger]:after {
            margin-top: 0;
            -webkit-transform: rotate(-45deg);
                   transform: rotate(-45deg);
        }
    }

    [dropdown-trigger] {
        @include flexbox;
        @include flex-flow(row nowrap);
        @include align-items(center);

        &:after {
            content: '';
            display: inline-block;
            margin-left: map-get($grid, gutter);
            margin-top: -4px;
            width: 0.5em;
            height: 0.5em;
            border-top: solid 2px currentColor;
            border-right: solid 2px currentColor;
            transition: transform 250ms ease-out;
            -webkit-transform: rotate(135deg);
                    transform: rotate(135deg);
            @include flex-shrink(0);
        }

        &.pill.outline {
            line-height: 3*$spas;
            padding-left: 3*$spas;
            padding-right: 3*$spas;
            border-width: 2px;
            font-weight: bold;

            &.secondary:hover {
                background: transparent;
                color: theme-color(--primary);
            }
        }
    }

    .btn-icon.bg-undefined {
        color: theme-color(--shade-60);
        &:hover {
            color: theme-color(--shade);
        }
        &[disabled] {
            color: theme-color(--shade-40);
            background: none;
        }
    }

    .dropdown-menu {
        opacity: 0;
        position: absolute;
        z-index: -1;
        pointer-events: none;
        margin-top: 0;

        &.down { top: 100%;}
        &.top { bottom: 100%; }

        &.right { right: 0;}
        &.left { left: 0;}

        &.center {
            left: 50%;
            transform: translateX(-50%);
        }
    }



}

/// dropdown styling

.dropdown {

    .dropdown-backdrop {
        background-color: map-get($dropdown, backdrop);
    }

    .dropdown-menu {
        border-width: 0px;
        border-style: solid;
        padding: calc($spas / 2) 0;
        min-width: 100%;
        color: map-get($dropdown, color);
        background: map-get($dropdown, background);
        border-color: map-get($dropdown, divider);
        border-radius: map-get($dropdown, border-radius);
        box-shadow: map-get($dropdown, shadow);

        button {
            width: 100%;
            @include flexbox;
            @include flex-flow(row nowrap);
            height: map-get($button, height);
            font-size: map-get($button, font-size);
            padding: $spas map-get($grid,gutter)*2;
            line-height: map-get($button, height) - 2*$spas;

                &:hover {
                    background: theme-color(--primary, 0.05);
                }

                &:focus {
                    background: theme-color(--primary, 0.02);
                }
            }
    }


}