// gradient (used for animated progresive placeholders)

@-webkit-keyframes gradient{
	0%{
        background-position: -468px 0
    }
    100%{
        background-position: 468px 0
    }
}
@-moz-keyframes gradient {
	0%{
        background-position: -468px 0
    }
    100%{
        background-position: 468px 0
    }
}
@-o-keyframes gradient {
	0%{
        background-position: -468px 0
    }
    100%{
        background-position: 468px 0
    }
}
@keyframes gradient {
	0%{
        background-position: -468px 0
    }
    100%{
        background-position: 468px 0
    }
}

// Blur animation

@-webkit-keyframes blur {
    0% {
        z-index: 90;
        opacity: 0.3;
    }
    70% {
        z-index: 90;
        opacity: 0.3;
    }
    100% {
        z-index: 0;
        opacity: 0;
    }
}

@-moz-keyframes blur {
    0% {
        z-index: 90;
        opacity: 0.3;
    }
    70% {
        z-index: 90;
        opacity: 0.3;
    }
    100% {
        z-index: 0;
        opacity: 0;
    }
}

@-o-keyframes blur {
    0% {
        z-index: 90;
        opacity: 0.3;
    }
    70% {
        z-index: 90;
        opacity: 0.3;
    }
    100% {
        z-index: 0;
        opacity: 0;
    }
}

@keyframes blur {
    0% {
        z-index: 90;
        opacity: 0.3;
    }
    70% {
        z-index: 90;
        opacity: 0.3;
    }
    100% {
        z-index: 0;
        opacity: 0;
    }
}

// empshasys animation

@-webkit-keyframes emphasys {
    0% {
        transform: scale(1);
    }
    25% {
        transform: scale(1.2);
    }
    50% {
        transform: scale(1);
    }
    75% {
        transform: scale(1.05);
    }
    100% {
        transform: scale(1);
    }
}

@-moz-keyframes emphasys {
    0% {
        transform: scale(1);
    }
    25% {
        transform: scale(1.2);
    }
    50% {
        transform: scale(1);
    }
    75% {
        transform: scale(1.05);
    }
    100% {
        transform: scale(1);
    }
}

@-o-keyframes emphasys {
    0% {
        transform: scale(1);
    }
    25% {
        transform: scale(1.2);
    }
    50% {
        transform: scale(1);
    }
    75% {
        transform: scale(1.05);
    }
    100% {
        transform: scale(1);
    }
}

@keyframes emphasys {
    0% {
        transform: scale(1);
    }
    25% {
        transform: scale(1.2);
    }
    50% {
        transform: scale(1);
    }
    75% {
        transform: scale(1.05);
    }
    100% {
        transform: scale(1);
    }
}

// apear animation
@-webkit-keyframes appear {
    0% {
        transform: scale(0);
    }
    100% {
        transform: scale(1);
    }
}

@-moz-keyframes appear {
    0% {
        transform: scale(0);
    }
    100% {
        transform: scale(1);
    }
}

@-o-keyframes appear {
    0% {
        transform: scale(0);
    }
    100% {
        transform: scale(1);
    }
}

@keyframes appear {
    0% {
        transform: scale(0);
    }
    100% {
        transform: scale(1);
    }
}

// spin animation
@-webkit-keyframes spin {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

@-moz-keyframes spin {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

@-o-keyframes spin {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

// spin counter clockwise animation
@-webkit-keyframes spin-reverse {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(-360deg);
    }
}

@-moz-keyframes spin-reverse {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(-360deg);
    }
}

@-o-keyframes spin-reverse {
    0% {
        transform: rotate(-0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

@keyframes spin-reverse {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(-360deg);
    }
}



// rotate animation

@-webkit-keyframes rotate {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(180deg);
    }
}

@-moz-keyframes rotate {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(180deg);
    }
}

@-o-keyframes rotate {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(180deg);
    }
}

@keyframes rotate {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(180deg);
    }
}

// growth animation
@-webkit-keyframes growth {
    0% {
        box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0);
        transform: scale(1);
    }
    100% {
        box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
        transform: scale(1.01);
    }
}

@-moz-keyframes growth {
    0% {
        box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0);
        transform: scale(1);
    }
    100% {
        box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
        transform: scale(1.01);
    }
}

@-o-keyframes growth {
    0% {
        box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0);
        transform: scale(1);
    }
    100% {
        box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
        transform: scale(1.01);
    }
}

@keyframes growth {
    0% {
        box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0);
        transform: scale(1);
    }
    100% {
        box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
        transform: scale(1.01);
    }
}

// shrink animation
@-webkit-keyframes shrink {
    0% {
        box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
        transform: scale(1.01);
    }
    100% {
        box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0);
        transform: scale(1);
    }
}

@-moz-keyframes shrink {
    0% {
        box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
        transform: scale(1.01);
    }
    100% {
        box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0);
        transform: scale(1);
    }
}

@-o-keyframes shrink {
    0% {
        box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
        transform: scale(1.01);
    }
    100% {
        box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0);
        transform: scale(1);
    }
}

@keyframes shrink {
    0% {
        box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
        transform: scale(1.01);
    }
    100% {
        box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0);
        transform: scale(1);
    }
}

// pulse animation
@-webkit-keyframes pulse {
    0% {
        transform : scale(0.9);
    }
    25% {
        transform : scale(1);
    }
    50% {
        transform : scale(0.9);
    }
    100% {
        transform : scale(1);
    }
}

@-moz-keyframes pulse {
    0% {
        transform : scale(0.9);
    }
    25% {
        transform : scale(1);
    }
    50% {
        transform : scale(0.9);
    }
    100% {
        transform : scale(1);
    }
}

@-o-keyframes pulse {
    0% {
        transform : scale(0.9);
    }
    25% {
        transform : scale(1);
    }
    50% {
        transform : scale(0.9);
    }
    100% {
        transform : scale(1);
    }
}

@keyframes pulse {
    0% {
        transform : scale(0.9);
    }
    25% {
        transform : scale(1);
    }
    50% {
        transform : scale(0.9);
    }
    100% {
        transform : scale(1);
    }
}

// toast animation
@-webkit-keyframes toast {
    0%{
        opacity:0;
        transform:scale(0.9);
    }
    80%{
        opacity:1;
        transform:scale(1.02);
    }
    100%{
        transform: scale(1);
    }
}

@-moz-keyframes toast {
    0%{
        opacity:0;
        transform:scale(0.9);
    }
    80%{
        opacity:1;
        transform:scale(1.02);
    }
    100%{
        transform: scale(1);
    }
}

@-o-keyframes toast {
    0%{
        opacity:0;
        transform:scale(0.9);
    }
    80%{
        opacity:1;
        transform:scale(1.02);
    }
    100%{
        transform: scale(1);
    }
}

@keyframes toast {
    0%{
        opacity:0;
        transform:scale(0.9);
    }
    80%{
        opacity:1;
        transform:scale(1.02);
    }
    100%{
        transform: scale(1);
    }
}

@-webkit-keyframes toast-exit {
    0%{
        opacity:1;
        top:0px;
    }
    100%{
        opacity:0;
        top:-100px;
    }
}

@-moz-keyframes toast-exit {
    0%{
        opacity:1;
        top:0px;
    }
    100%{
        opacity:0;
        top:-100px;
    }
}

@-o-keyframes toast-exit {
    0%{
        opacity:1;
        top:0px;
    }
    100%{
        opacity:0;
        top:-100px;
    }
}

@keyframes toast-exit {
    0%{
        opacity:1;
        top:0px;
    }
    100%{
        opacity:0;
        top:-100px;
    }
}

// fade in animation
@-webkit-keyframes fade-in {
    0%{
        opacity:0;
    }
    100%{
        opacity:1;
    }
}

@-webkit-keyframes fade-in-tweak {
    0%{
        opacity:0;
        height:0;
    }
    100%{
        opacity:1;
        height:49px;
    }
}

@-moz-keyframes fade-in {
    0%{
        opacity:0;
    }
    100%{
        opacity:1;
    }
}

@-moz-keyframes fade-in-tweak {
    0%{
        opacity:0;
        height:0;
    }
    100%{
        opacity:1;
        height:49px;
    }
}

@-o-keyframes fade-in {
    0%{
        opacity:0;
    }
    100%{
        opacity:1;
    }
}

@-o-keyframes fade-in-tweak {
    0%{
        opacity:0;
        height:0;
    }
    100%{
        opacity:1;
        height:49px;
    }
}

@keyframes fade-in {
    0%{
        opacity:0;
    }
    100%{
        opacity:1;
    }
}

@keyframes fade-in-tweak {
    0%{
        opacity:0;
        height:0;
    }
    100%{
        opacity:1;
        height:49px;
    }
}


// shake horizontal (used with disabled btns)

@-webkit-keyframes shake-horizontal {
    0%,
    100% {
      -webkit-transform: translateX(0);
              transform: translateX(0);
    }
    10%,
    30%,
    50%,
    70% {
      -webkit-transform: translateX(-10px);
              transform: translateX(-10px);
    }
    20%,
    40%,
    60% {
      -webkit-transform: translateX(10px);
              transform: translateX(10px);
    }
    80% {
      -webkit-transform: translateX(8px);
              transform: translateX(8px);
    }
    90% {
      -webkit-transform: translateX(-8px);
              transform: translateX(-8px);
    }
}

@keyframes shake-horizontal {
    0%,
    100% {
      -webkit-transform: translateX(0);
              transform: translateX(0);
    }
    10%,
    30%,
    50%,
    70% {
      -webkit-transform: translateX(-10px);
              transform: translateX(-10px);
    }
    20%,
    40%,
    60% {
      -webkit-transform: translateX(10px);
              transform: translateX(10px);
    }
    80% {
      -webkit-transform: translateX(8px);
              transform: translateX(8px);
    }
    90% {
      -webkit-transform: translateX(-8px);
              transform: translateX(-8px);
    }
}


// shake horizontal (used with disabled btns)

@-webkit-keyframes shake-vertical {
    0%,
    100% {
      -webkit-transform: translateY(0);
              transform: translateY(0);
    }
    10%,
    30%,
    50%,
    70% {
      -webkit-transform: translateY(-10px);
              transform: translateY(-10px);
    }
    20%,
    40%,
    60% {
      -webkit-transform: translateY(10px);
              transform: translateY(10px);
    }
    80% {
      -webkit-transform: translateY(8px);
              transform: translateY(8px);
    }
    90% {
      -webkit-transform: translateY(-8px);
              transform: translateY(-8px);
    }
}

@keyframes shake-vertical {
    0%,
    100% {
      -webkit-transform: translateY(0);
              transform: translateY(0);
    }
    10%,
    30%,
    50%,
    70% {
      -webkit-transform: translateY(-10px);
              transform: translateY(-10px);
    }
    20%,
    40%,
    60% {
      -webkit-transform: translateY(10px);
              transform: translateY(10px);
    }
    80% {
      -webkit-transform: translateY(8px);
              transform: translateY(8px);
    }
    90% {
      -webkit-transform: translateY(-8px);
              transform: translateY(-8px);
    }
}

/**
 * ----------------------------------------
 * animation slide-bottom
 * ----------------------------------------
 */

 @-webkit-keyframes slide-bottom {
    0% {
      -webkit-transform: translateY(0);
              transform: translateY(0);
    }
    100% {
      -webkit-transform: translateY(100%);
              transform: translateY(100%);
    }
  }
  @keyframes slide-bottom {
    0% {
      -webkit-transform: translateY(0);
              transform: translateY(0);
    }
    100% {
      -webkit-transform: translateY(100%);
              transform: translateY(100%);
    }
  }

  /**
 * ----------------------------------------
 * animation slide-top
 * ----------------------------------------
 */
@-webkit-keyframes slide-top {
    0% {
      -webkit-transform: translateY(0);
              transform: translateY(0);
    }
    100% {
      -webkit-transform: translateY(-100%);
              transform: translateY(-100%);
    }
  }
  @keyframes slide-top {
    0% {
      -webkit-transform: translateY(0);
              transform: translateY(0);
    }
    100% {
      -webkit-transform: translateY(-100%);
              transform: translateY(-100%);
    }
  }

// overflow animation
@-webkit-keyframes delay-overflow-auto {
    from { overflow: hidden; }
}

@keyframes delay-overflow-auto {
    from { overflow: hidden; }
}

@keyframes bounceAlpha {
    0% {opacity: 1; transform: translateY(0px) scale(1);}
    25% {opacity: 0; transform: translateY(30px) scale(0.9);}
    26% {opacity: 0; transform: translateY(-10px) scale(0.9);}
    55% {opacity: 1; transform: translateY(0px) scale(1);}
}