$block-btn: (
    bg: #fff,
    border-radius: 5px,
    shadow: 0 1px 5px rgba(0,0,0,.35),
    padding: 16px,
    align: left
);

.block-btn {
  background-color: deep-map($block-btn,bg);
  border-radius: deep-map($block-btn,border-radius);
  box-shadow: deep-map($block-btn,shadow);
  padding: deep-map($block-btn,padding);
  text-align: deep-map($block-btn,align);
  white-space: normal;
  line-height: 1em;
      img {
          max-width: 80px;
          max-height: 80px;
              @include breakpoint-to(sm) {
                  max-width: 40px;
                  max-height: 40px;
              }
      }
      h4 {
          font-size: 1.25em; line-height: 1.3em;
      }
      &:hover, &:focus {
          transform: translate(-1px, -1px);
          transition: transform 150ms cubic-bezier(0.25, 0.46, 0.45, 0.94);
          box-shadow: 1px 3px 8px rgba(0,0,0,.3);
      }
}