progress {
    width: 100%;
    height: map-get($progress, height);

    &::-webkit-progress-value {
        transition: width 250ms ease-out;
        -webkit-transition: width 250ms ease-out;
    }
}

progress[value] {
    width: 100%;
    border: none;
    -moz-appearance: none;
    -webkit-appearance: none;
    appearance: none;
    height: map-get($progress, height);
    max-height: map-get($progress, height);

    &::-webkit-progress-bar {
        background-color: map-get($progress, bar-bg);
        border-radius: map-get($progress, border-radius);
    }

    &::-webkit-progress-value {
        background-color: currentColor;
        border-radius: map-get($progress, border-radius);
    }

    &::-moz-progress-bar {
        background-color: currentColor;
        border-radius: map-get($progress, border-radius);
    }
}