/// default placeholders for state classes, generated based on global $states variable
/// It's always better to @extend than @include
@each $stateClass, $state in $states {
    %#{$stateClass}State {
        background-color: map-get($state, color);
        color: map-get($state, contrast);
    }
}

@mixin states($customStates){
    @if($customStates != $states) {
        @each $stateClass, $state in $customStates {
            &.#{$stateClass} {
                background-color: map-get($state, color);
                color: map-get($state, contrast);
            }
        }
    }
    @else {
        @each $stateClass, $state in $states {
            &.#{$stateClass} {
                @extend  %#{$stateClass}State;
            }
        }
    }
}