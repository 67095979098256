// Forma generala
// clasele 'vertical' si 'spinner-2/3/4x' sunt optionale. 
// din 'vertical' se controleaza directia (daca nu are vertical e automat orizontal)
// 'spinner-2x/3x/4x' dimensiunea
// 'absolute' daca se doreste efectul de hover & disabled (trebuie sa fie continut intr-un element cu pozitie relativa pentru asta)

// <ui-loader type="spinner" size="2x"></ui-loader>

.loader {
    width: 100%;
    min-width: 1em;
    @include flexbox;
    @include align-items(center);
    @include justify-content(center);
    font-size: map-get($theme, font-size);

    &.primary {
        color: theme-color(--primary);
    }

    &.vertical {
        @include flex-flow(column wrap);
    }

    &.size-2x  {
        font-size: 2 * map-get($theme, font-size);
    }

    &.size-3x {
        font-size: 3 * map-get($theme, font-size);
    }

    &.size-4x {
        font-size: 4 * map-get($theme, font-size);
    }

    &.size-5x{
        font-size: 5 * map-get($theme, font-size);
    }

    &.size-11x{
        font-size: 175px;
    }

    &.absolute {
        position: absolute;
        top: 0;
        left:0;
        bottom: 0;

        &:before {
            opacity: 0.8;
            content: "";
            display: block;
            position: absolute;
            left:0;
            top:0;
            width: 100%;
            height: 100%;
            background-color: #fff;
        }
    }   

    .spinner {
        color: inherit;
        position: relative;
        border: 0.16em solid currentColor;        
        border-top-color: transparent;
        border-radius: 50%;
        max-width: 1em;
        height: 1em;
        flex: 0 0 1em;
        -webkit-animation: spin 0.7s linear infinite; /* Safari */
        animation: spin 0.7s linear infinite;
        margin-left: 8px;
        margin-right: 8px;
        box-sizing: border-box;
    }

    p {
        position: relative;
        font-size: 14px;
        margin: 0.5em 0;
        color: inherit;
    }

}

// data protection loader
.loader .data-protection {
    display: block;
    width: 1em;
    height: 1em;
    position: relative;

    &:before, &:after {
        content: "";
        display: block;
        position: absolute;
        width: 100%;
        height: 100%;
        background-position: center;
        background-repeat: no-repeat;
    }

    &:before {
        background-image: url('/ux/images/privacy/scan@2X.png');
        @include animate(spin, 2000ms, linear, infinite);
        background-size: 65%;
        
    }

    &:after {
        @include animate(spin-reverse, 4000ms, linear, infinite);     
        background-image: url('/ux/images/privacy/dpyRing@2x.png');
        background-size: cover;
    }
}
